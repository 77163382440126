/************************************** Estilos para telas grandes ********************************************/
@media (min-width: 767px) {

    .depoimentos {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow-x: auto; /* Permite rolagem horizontal */
        padding-bottom: 20px;
        scroll-snap-type: x mandatory; /* Para um efeito de "snap" suave ao deslizar */
    }
    
    .depoimentos p {
        text-align: center;
    }
    
    .cardDepo {
        height: 80%;
        width: 250px;
        background-color: #212529;
        border: 1px solid #303132;
        border-radius: 8px;
        margin-left: 8px;
        margin-right: 8px; 
        padding: 10px;
        flex-shrink: 0; /* Impede que o card encolha */
        scroll-snap-align: center; /* Faz os cards pararem no centro ao deslizar */
        transition: box-shadow 0.3s;
    }
    
    .cardDepo:hover {
        box-shadow: 0 0 5px #1C7ED6;
    }
    
    .depoimentos img {
        padding-right: 8px;
    }
    
    .head {
        height: 8vh;
        display: flex;
        padding: 5px;
        color: white;
        align-items: center;
    }
    
    .nota {
        height: 5vh;
        align-items: center;
        justify-content: center;
    }
    
    .body {
        height: 23vh;
        font-size: medium;
        overflow-x: hidden;
        text-align: justify; 
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .data {
        height: 4vh;
        margin: auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: small;
    }
    
    }
    
    /******************************* Estilos para telas pequenas ******************************************/
    @media (max-width: 767px) {
    
    .depoimentos {
        height: 50vh;
        display: flex;
        justify-content: start;
        overflow-x: auto; /* Permite rolagem horizontal */
        padding-bottom: 10px;
        scroll-snap-type: x mandatory;
    }
    
    .cardDepo {
        height: 40vh;
        width: 80%; /* Ajusta para ocupar mais espaço em telas pequenas */
        background-color: #212529;
        border: 1px solid #303132;
        border-radius: 8px;
        margin-left: 8px;
        margin-right: 8px; 
        padding: 10px;
        flex-shrink: 0;
        scroll-snap-align: center;
    }
    
    .depoimentos img {
        padding-right: 5px;
    }
    
    .head {
        height: 8vh;
        display: flex;
        color: white;
        align-items: center;
    }
    
    .nota {
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .body {
        height: 23vh;
        overflow-x: hidden;
        text-align: justify; 
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .data {
        height: 4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: small;
    }
    }
    