/************************************** Estilos para telas grandes ********************************************/
@media (min-width: 767px) {
    .contato {
        height: 100vh;
    }

    .titulo {
        display: flex;
        flex-direction: column;
    }
    
    .containerContato {
        height: 90vh;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
    }

    .containerFilho1 p {
        text-align: center;
      }

    
    .containerFilho2 {
        text-align: center;
        height: 20vh;
        display: flex;
    }
    
    
    .rede {
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    
    .rede img {
        margin: auto;
        display: flex;
    }
    
    .rede img:hover {
        cursor: pointer;
        filter: brightness(1.3);
    }
    
    .footer {
        position:static;
        bottom: 0;
        height: 10vh;
        background-color: #212529;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #303132;
    }
    
    .footer p {
        text-align: center;
        align-items: center;
        z-index: 10;
    }
}


/******************************* Estilos para telas pequenas ******************************************/
@media (max-width: 767px) {
    .contato {
        height: 100vh;
    }
    
    .containerFilho1 p {
        text-align: center;
      }
      
    .containerFilho2{
        text-align: center;
    }
    .footer p {
        
        text-align: center;
        align-items: center;
        z-index: 10;
        
    }
    .footer  {
        background-color: #212529;
        padding: 0.5vh;
    }
}
