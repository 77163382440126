/* Personalize a barra de rolagem */
::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
  }
  
  /* Cor da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor cinza da barra de rolagem */
    border-radius: 4px; /* Borda arredondada */
  }
  
  /* Cor do fundo da barra de rolagem */
  ::-webkit-scrollbar-track {
    background: #333; /* Cor de fundo da barra de rolagem */
  }
  
