/************************************** Estilos para telas grandes ********************************************/
@media (min-width: 767px) {
  .sobre {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#gabi {
  width: 300px;
  border-radius: 50%;
  transform-origin: center;
  animation: shadowColorChange 4s linear infinite, fadeIn 2s linear forwards;
}

.img {
  padding: 50px;
}

.desc {
  padding: 50px;
  text-align: start !important;
  flex-direction: column;
  animation: fadeIn 2s linear forwards;
}
  
.desc h2 {
  color: #FFF;
}

#btn {
  position: fixed;
  bottom: 5vh;
  background-color: transparent;
  outline: none;
  color: #5b8cb8;
  width: 20vh;
  animation: slideInFromBottom 2s ease forwards;
}

#btn:hover{
  cursor: pointer;
  filter: brightness();
}
}

/******************************* Animações ******************************************/
@keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}
  
  
@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shadowColorChange {
  0% {
    box-shadow: 0 0 20px #0a34de89;
  }
  50% {
    box-shadow: 0 0 20px rgba(237, 237, 241, 0.5);
  }
  100% {
    box-shadow: 0 0 20px #0a34de89;
  }

}


/******************************* Estilos para telas pequenas ******************************************/
@media (max-width: 767px)  {

.sobre {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  height: 100vh;
  padding: 0 auto;
  margin:0 auto;

}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.sobre img {
  width: 200px;
  border-radius: 50%;
  transform-origin: center;
  animation: shadowColorChange 4s linear infinite, fadeIn 2s linear forwards;
    
}
  
#btn {
  position: fixed;
  bottom: 5vh;
  background-color: transparent;
  outline: none;
  color: #5b8cb8;
  width: 20vh;
  animation: slideInFromBottom 2s ease forwards;
}

#btn:hover{
  cursor: pointer;
  filter: brightness();
}
.desc {
  text-align: start;
  flex-direction: column;
  animation: fadeIn 2s linear forwards;
  height: 50vh;
  align-items: center;
  display:flex;
}
  
.desc h2 {
  color: #FFF;
}


}

