/************************************** Estilos para telas grandes ********************************************/
@media (min-width: 767px) {


.jobs {
height: 100vh;
justify-content: center;
display: flex;
flex-direction: column;
}


.titulo {
  text-align: center;
  margin: auto;
}

.superior {
    height: 50vh;
}

.inferior {
    height: 50vh;
}


.linhaTempo {
    display: flex;
    justify-content: center;

}

.flecha {
    display: flex;
    align-items: center; /* Alinhar elementos verticalmente no centro */
    justify-content: center;
}

.flecha-container {
    display: flex;
    align-items: center; /* Alinhar elementos verticalmente no centro */
    justify-content: center; /* Centralizar horizontalmente */
}

.bola {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1C7ED6;
}

.linha {
    background-color: #1C7ED6;
    width: 25%;
    height: 5px; /* Ajuste a altura desejada da linha */
}

.ponta {
    width: 0;
    height: 0;
    border-left: 20px solid #1e90ff; /* Borda esquerda preenchida */
    border-right: 20px solid transparent; /* Borda direita transparente */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}


.parte {
    height: 100%;
    width: 25%;
    padding-left: 25px;
}

.card {
    height: 100%;
    width: 15%;
    padding: 10px;
    background-color: #212529;
    border: 1px solid #303132;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px; 
}

.card:hover {
    box-shadow: 0 0 5px #1C7ED6;
}

select {
    background-color: transparent;
    color: #ADB5BD;
    font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    font-weight: 300; 
    outline: none;
    border: 0px 0px 0px 1px solid #303132;
}

.option{
    background-color: #212529;
}

}

/******************************* Estilos para telas pequenas ******************************************/
@media (max-width: 767px) {
    .jobs {
        height: 200vh;
        justify-content: center;
        display: block;
        flex-direction: column;
    }
    
    .titulo {
        height: 10vh;
      text-align: center;
      margin: auto;
    }
    
    .superior {
        height: 90vh;
        display: flex;
    }
    
    .inferior {
        height: 100vh;
        display: block;
    }
    
    .linhaTempo {
        display: block;
        justify-content: center;
        height: 100%;
        padding-right: 10px;

    }
    
    .flecha {
        display: flex;
        flex-direction: column;
        align-items: center; /* Alinhar elementos verticalmente no centro */
        justify-content: center;
        width: max-content;


    }
    
    .flecha-container {
        display: flex;
        justify-content: center; /* Centralizar horizontalmente */
        
    }
    
    .bola {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #1C7ED6;
    }
    
    .linha {
        background-color: #1C7ED6;
        height: 26%; /* Ajuste a altura desejada da linha */
        width: 5px; /* Ajuste a largura desejada da linha */
    }
    
    .ponta {
        width: 0;
        height: 0;
        border-top: 20px solid #1e90ff; /* Borda superior preenchida */
        border-bottom: 20px solid transparent; /* Borda inferior transparente */
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    

    .parte {
        display: block;
        padding-left: 25px;
        height: 30%;
    }

    .parte hr {
        display: none;
    }
    
    .card {
        height: min-content;
        width: 80%;
        padding: 10px;
        margin: 20px;
        background-color: #212529;
        border: 1px solid #303132;
        border-radius: 8px;
        text-align: center;
 
    }

    select {
        background-color: transparent;
        color: #ADB5BD;
        font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
        font-weight: 300; 
        outline: none;
        border: 0px 0px 0px 1px solid #303132;
    }
    
    .option{
        background-color: #212529;
    }

    
}

