
body {
  background-color: #181A1C;
  color: #ADB5BD;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-weight: 300; 
  height: 100vh;
  margin: auto;
}

a {
  color: #1C7ED6;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

@media (max-width: 767px) {
  body {
    height: 100vh;
  margin: auto;
  }
  
}